<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <OpexFormulario :idTipoOpex="idTipoOpex" :tipoOpex="tipoOpex" :nombreTipoOpex="nombreTipoOpex">
          </OpexFormulario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OpexGastoAdmFormEditar',
  components: {
    OpexFormulario: () => import('../../components/OpexFormulario')
  },
  data: () => ({
    title: 'EDITAR OPEX GASTO ADMINISTRATIVO',
    page: {
      title: 'Editar OPEX Gasto Administrativo'
    },
    breadcrumbs: [
      {
        text: 'Presupuesto',
        disabled: true
      },
      {
        text: 'OPEX  Gasto Administrativo',
        disabled: true
      },
      {
        text: 'Editar',
        disabled: false
      }
    ],
    idTipoOpex: 3,
    tipoOpex: 'gadm',
    nombreTipoOpex: 'Gasto Administrativo'
  }),
  methods: {
    open() {},
    close() {}
  },
  created() {}
};
</script>
